import { useRouter } from 'next/router';
import React from 'react';
import { IoIosLogIn } from 'react-icons/io';
import BBButton from '@/base_blocks/src/bbbutton';
import type { TBBButtonIconAlign, TBBButtonSize } from '@/base_blocks/src/types';
import { SIZE_ICON_SM, SIZE_ICON_MD, SIZE_ICON_LG } from '@/constants/sizes';
import Routes from 'routes';

/**
 * IProps
 */
interface IPropsButtonLogin {
  size?: TBBButtonSize;
  showText?: boolean;
  elevation?: boolean;
  iconAlignment?: TBBButtonIconAlign;
  variant?: 'primary' | 'secondary';
}

/**
 * BUTTON LOGIN
 */
export const ButtonLogin = (props: IPropsButtonLogin) => {
  const { showText = true, elevation = false, size = 'md', iconAlignment = 'right', variant = 'primary' } = props;
  const router = useRouter();

  const getIconSize = () => {
    switch (size) {
      case 'sm':
        return SIZE_ICON_SM;
      case 'md':
        return SIZE_ICON_MD;
      case 'lg':
        return SIZE_ICON_LG;
      default:
        return SIZE_ICON_MD;
    }
  };

  /**
   * RENDER
   */
  
  return (
    <BBButton
      variant={variant}
      size={size}
      text={showText ? 'Login' : undefined}
      icon={{ icon: <IoIosLogIn size={getIconSize()} />, align: iconAlignment }}
      elevation={elevation ? 'medium' : 'none'}
      onClick={() => router.push(Routes.routeLogin())}
    />
  );
  
};