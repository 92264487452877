import IUser from '@/models/users/IUser';

/**
 * Check if user is logged in.
 *
 * @param {IUser} user - The user to check.
 */
export const isLoggedIn = (user: IUser | null): boolean => {
  if (user === null) {
    return false;
  }
  return !(typeof user.id === 'undefined' || user.id == '');
};