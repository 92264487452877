import React from 'react';
import { useRecoilState } from 'recoil';
import BBLink from '@/base_blocks/src/bblink';
import BBText from '@/base_blocks/src/bbtext';
import ButtonThemeSwitcher from '@/components/buttons/button_theme_switcher';
import styles from '@/components/footer/styles.module.scss';
import { getProjectSettingsEnvTextColor } from '@/models/core/IProjectSettings';
import { stateUser } from '@/states/auth';
import { stateProjectSettings } from '@/states/core';
import Routes from 'routes';

/**
 * FOOTER
 */
export default function Footer(): React.ReactElement {
  // recoil state
  const [projectSettings] = useRecoilState(stateProjectSettings);
  const [user] = useRecoilState(stateUser);
  const year = new Date().getFullYear();
  const project_author_res = 'Piccy Bank Inc.';

  return (
    <div className={styles.footer}>
      <div className={styles.row}>
        <div>
          <BBText size="large" bold>
            About
          </BBText>
          <BBLink href={Routes.routeHome()}>Home</BBLink>
          <BBLink href={Routes.routeAbout()}>About</BBLink>
          <BBLink href={Routes.routePricing()}>Pricing</BBLink>
          <BBLink href={Routes.routeFaq()}>FAQ</BBLink>
        </div>
        <div>
          <BBText size="large" bold>
            Legal
          </BBText>
          <BBLink href={Routes.routeLegalTOS()}>Terms of Service</BBLink>
          <BBLink href={Routes.routeLegalPrivacyPolicy()}>Privacy Policy</BBLink>
        </div>
        <div>
          <BBText size="large" bold>
            Support
          </BBText>
          <BBLink href={Routes.routeContact()}>Contact</BBLink>
          {!!user && <BBLink href={Routes.routeUserSupport(user.id)}>Support</BBLink>}
          {!!user && <BBLink href={Routes.routeApiDocs()}>API Docs</BBLink>}
        </div>
      </div>
      <div className={styles.sectionBottom}>
        <BBLink size="large" href={Routes.routeHome()}>
          {project_author_res}
        </BBLink>
        <BBText size="tiny" color="grey_light">
          Copyright {year} &#169;
        </BBText>
        <BBText size="tiny" color="grey_light">
          Version {projectSettings.version}
        </BBText>
        <BBText size="tiny" color={getProjectSettingsEnvTextColor(projectSettings.environment)}>
          {projectSettings.environment}
        </BBText>
        <ButtonThemeSwitcher />
      </div>
    </div>
  );
}
