import classnames from 'classnames';
import React from 'react';
import styles from '@/components/containers/floating/styles.module.scss';

export type TContainerFloatingButtonAlignment = 'bottom_left' | 'bottom_right';

/**
 * Props
 *
 * @param {React.ReactNode} children - children to display, can be button or list of buttons or whatever
 * @param {TContainerFloatingButtonAlignment} alignment - alignment of the button
 * @param {string=} className - class name
 */
interface IPropsContainerFloating {
  children: React.ReactNode;
  alignment: TContainerFloatingButtonAlignment;
  className?: string;
}

/**
 * CONTAINER FLOATING
 */
export default function ContainerFloating(Props: IPropsContainerFloating): React.ReactElement {
  const { children, alignment, className } = Props;

  /**
   * RENDER
   */

  return <div className={classnames(styles[alignment], className)}>{children}</div>;
}
