import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { useRecoilState } from 'recoil';
import BBButton from '@/base_blocks/src/bbbutton';
import type { TBBButtonSize } from '@/base_blocks/src/types';
import Logo from '@/images/logo-white.png';
import { stateUser } from '@/states/auth';
import Routes from 'routes';

/**
 * PROPS
 *
 * @param {TBBButtonSize} size - Size of the button
 * @param {string} className - Class name to use for the button
 */
interface IPropsButtonDashboard {
  size?: TBBButtonSize;
  className?: string;
}

/**
 * BUTTON DASHBOARD
 */
export default function ButtonDashboard(props: IPropsButtonDashboard): React.ReactElement | null {
  const { size = 'md', className } = props;
  const [user] = useRecoilState(stateUser);
  const router = useRouter();

  if (!user) {
    return null;
  }

  const getWidth = (): number => {
    switch (size) {
      case 'sm':
        return 16;
      case 'md':
        return 22;
      case 'lg':
        return 28;
      default:
        return 20;
    }
  };

  const getHeight = (): number => {
    switch (size) {
      case 'sm':
        return 12;
      case 'md':
        return 18;
      case 'lg':
        return 24;
      default:
        return 15;
    }
  };

  /**
   * RENDER
   */

  return (
    <BBButton
      variant="primary"
      text="Dashboard"
      size={size}
      // eslint-disable-next-line
      icon={{ icon: <Image src={Logo.src} alt="" height={getHeight()} width={getWidth()} /> }}
      elevation="none"
      className={className}
      onClick={() => router.push(Routes.routeUserDashboard(user.id))}
    />
  );
}
