import { useRouter } from 'next/router';
import React from 'react';
import { IoCreateOutline } from 'react-icons/io5';
import BBButton from '@/base_blocks/src/bbbutton';
import type { TBBButtonIconAlign, TBBButtonSize } from '@/base_blocks/src/types';
import { SIZE_ICON_LG, SIZE_ICON_MD, SIZE_ICON_SM } from '@/constants/sizes';
import Routes from 'routes';

/**
 * IProps
 */
interface IPropsButtonSignup {
  size?: TBBButtonSize;
  showText?: boolean;
  elevation?: boolean;
  iconAlignment?: TBBButtonIconAlign;
  variant?: 'primary' | 'secondary';
}

/**
 * BUTTON SIGNUP
 */
export const ButtonSignup = (props: IPropsButtonSignup) => {
  const { showText = true, elevation = false, size = 'md', iconAlignment = 'right', variant = 'secondary' } = props;
  const router = useRouter();

  const getIconSize = () => {
    switch (size) {
      case 'sm':
        return SIZE_ICON_SM;
      case 'md':
        return SIZE_ICON_MD;
      case 'lg':
        return SIZE_ICON_LG;
      default:
        return SIZE_ICON_MD;
    }
  };

  /**
   * RENDER
   */
  
  return (
    <BBButton
      variant={variant}
      size={size}
      icon={{ icon: <IoCreateOutline size={getIconSize()} />, align: iconAlignment }}
      text={showText ? 'Signup' : undefined}
      elevation={elevation ? 'medium' : 'none'}
      onClick={() => router.push(Routes.routeSignup())}
    />
  );
  
};