import Head from 'next/head';
import React from 'react';
import type { GetStaticProps } from 'next';
import { Environment } from 'environment';

interface IPropsHeadHtmlTags {
  title: string;
  description: string;
  keywords: string;
  url: string;
  image: string;
  author: string;
  publishedDate?: string;
}

function getDefaultProps(uriSite: string = ''): IPropsHeadHtmlTags {
  return {
    title: 'Piccy Bank',
    description: 'The modern age data harvester',
    keywords:
      'soil nerd, soil, nerd, startup, technology, business, unicorn, fast, scalable, funding, venture, capital, markets, utility, saas, software',
    url: '/',
    image: `${uriSite}/images/logo-default.png`,
    author: 'Piccy Bank Inc.',
  };
}

/**
 * PROPS
 *
 * @param {string} title          Title of page
 * @param {string} description    Description of page
 * @param {string} keywords       Keywords to use for this page
 * @param {string} url            URL of page - relative (no leading '/') or FULL (include 'https')
 * @param {string} image          Image to use for social previews
 * @param {string} author         Author of page
 * @param {string} publishedDate  Published date of the page
 */
interface IPropsHeadHtml {
  description?: string;
  keywords?: string;
  title?: string;
  url?: string;
  image?: string;
  author?: string;
  publishedDate?: string;
}

/**
 * HEAD HTML
 */
export default function HeadHtml(Props: IPropsHeadHtml): React.ReactElement {
  const uriSite = Environment.uriSite;
  const defaultProps = getDefaultProps(uriSite);
  const {
    title = defaultProps.title,
    description = defaultProps.description,
    keywords = defaultProps.keywords,
    url = defaultProps.url,
    image = defaultProps.image,
    author = defaultProps.author,
    publishedDate,
  } = Props;

  const urlCleaned = url.toLowerCase().includes('https') || url.toLowerCase().includes('http') ? url : `${Environment.uriSite}/${url}`;

  /**
   * RENDER
   */
  return (
    <Head>
      <title>{title}</title>
      <base href="/" />
      {
        // eslint-disable-next-line
        <link rel="icon" href={'favicons/favicon.ico'} />
      }
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* FAVICONS */}
      <link rel="shortcut icon" type="image/x-icon" sizes="16x16 32x32 64x64 96x96" href="favicon.ico" />
      <link rel="shortcut icon" type="image/png" sizes="16x16" href="favicons/favicon-16x16.png" />
      <link rel="shortcut icon" type="image/png" sizes="32x32" href="favicons/favicon-32x32.png" />
      <link rel="shortcut icon" type="image/png" sizes="64x64" href="favicons/favicon-64x64.png" />
      <link rel="shortcut icon" type="image/png" sizes="96x96" href="favicons/favicon-96x96.png" />
      <link rel="shortcut icon" type="image/png" sizes="128x128" href="favicons/favicon-128x128.png" />
      <link rel="shortcut icon" type="image/png" sizes="256x256" href="favicons/favicon-256x256.png" />
      <link rel="shortcut icon" type="image/png" sizes="512x512" href="favicons/favicon-512x512.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="152x152" href="favicons/apple-touch-icon-152x152.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="167x167" href="favicons/apple-touch-icon-167x167.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="favicons/apple-touch-icon-180x180.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* Default properties */}
      <meta name="site_name" key="site_name" content={title} />
      <meta name="description" key="description" content={description} />
      <meta name="keywords" key="keywords" content={keywords} />
      <meta name="author" key="author" content={author} />
      <meta name="url" key="url" content={urlCleaned} />
      <meta name="image" key="image" content={image} />
      <meta name="type" key="type" content="website" />
      <meta name="locale" key="locale" content="en_US" />

      {/* OG properties */}
      <meta property="og:title" key="title" content={title} />
      <meta property="og:description" key="description" content={description} />
      <meta property="og:keywords" key="keywords" content={keywords} />
      <meta property="og:url" key="url" content={urlCleaned} />
      <meta property="og:image" key="image" content={image} />
      <meta property="og:author" key="author" content={author} />
      <meta property="og:type" key="type" content="website" />
      <meta property="og:locale" key="locale" content="en_US" />
      <meta property="og:site_name" key="site_name" content={title} />

      {/* Twitter properties */}
      <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" key="twitter:title" content={title} />
      <meta name="twitter:description" key="twitter:description" content={description} />
      <meta name="twitter:keywords" key="twitter:keywords" content={keywords} />
      <meta name="twitter:author" key="twitter:author" content={author} />
      <meta name="twitter:url" key="twitter:url" content={urlCleaned} />
      <meta name="twitter:image" key="twitter:image" content={image} />
      <meta name="twitter:site" key="twitter:site" content={author} />
      <meta name="twitter:creator" key="twitter:creator" content={author} />

      {/* OQ properties */}
      <meta name="oq:card" key="oq:card" content="summary_large_image" />
      <meta name="oq:title" key="oq:title" content={title} />
      <meta name="oq:description" key="oq:description" content={description} />
      <meta name="oq:keywords" key="oq:keywords" content={keywords} />
      <meta name="oq:author" key="oq:author" content={author} />
      <meta name="oq:url" key="oq:url" content={urlCleaned} />
      <meta name="oq:image" key="oq:image" content={image} />
      <meta name="oq:site" key="oq:site" content={author} />
      <meta name="oq:creator" key="oq:creator" content={author} />

      {/* Published date */}
      {publishedDate && (
        <>
          <meta name="article:published_time" key="article:published_time" content={publishedDate} />
          <meta name="article:modified_time" key="article:modified_time" content={publishedDate} />
          <meta name="og:published_time" key="og:published_time" content={publishedDate} />
          <meta name="og:modified_time" key="og:modified_time" content={publishedDate} />
          <meta name="twitter:published_time" key="twitter:published_time" content={publishedDate} />
          <meta name="twitter:modified_time" key="twitter:modified_time" content={publishedDate} />
          <meta name="oq:published_time" key="oq:published_time" content={publishedDate} />
          <meta name="oq:modified_time" key="oq:modified_time" content={publishedDate} />
        </>
      )}
    </Head>
  );
}

// TODO is this needed?
// The idea here is that we can pass in props to the component
// and it will be rendered on the server side with at least
// the default props. This is (potentially?) useful for SEO purposes.
export const getStaticProps: GetStaticProps = async (_) => {
  const uriSite = Environment.uriSite;
  return {
    props: getDefaultProps(uriSite),
  };
};
