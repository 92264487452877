import React from 'react';
import { useRecoilState } from 'recoil';
import BBNavbar from '@/base_blocks/src/bbnavbar';
import BBNavbarItem from '@/base_blocks/src/bbnavbar_item';
import ButtonLogout from '@/components/buttons/button_logout';
import ButtonsAuth from '@/components/buttons/buttons_auth';
import Logo from '@/images/logo-default.png';
import { stateUser } from '@/states/auth';
import { isLoggedIn } from '@/utils/auth';
import Routes from 'routes';

/**
 * PROPS
 * @param {string} title - title to use for navbar
 * @param {React.ReactNode} mainContent - children to render
 */
interface IPropsNavbarMain {
  title?: string;
  mainContent: React.ReactNode;
}

/**
 * NAVBAR MAIN
 */
export default function NavbarMain(Props: IPropsNavbarMain): React.ReactElement {
  const { title = 'Piccy Bank', mainContent } = Props;
  const [user] = useRecoilState(stateUser);

  /**
   * RENDER
   */
  return (
    <BBNavbar
      title={title}
      // eslint-disable-next-line
      imageSrc={Logo.src}
      imageWidth={70}
      mainContent={mainContent}
      buttonsAction={isLoggedIn(user) ? <ButtonLogout redirect={window.location.toString()} /> : <ButtonsAuth />}
    >
      <BBNavbarItem title="Home" href={Routes.routeHome()} />
      <BBNavbarItem title="About" href={Routes.routeAbout()}>
        <BBNavbarItem title="About" href={Routes.routeAbout()} />
        <BBNavbarItem title="FAQ" href={Routes.routeFaq()} />
        <BBNavbarItem title="Pricing" href={Routes.routePricing()} />
      </BBNavbarItem>
      <BBNavbarItem title="Contact" href={Routes.routeContact()} />
      {!!user && user.is_staff && <BBNavbarItem title="Admin" href={Routes.routeAdmin()} />}
    </BBNavbar>
  );
}
