import { useRouter } from 'next/router';
import React from 'react';
import { toast } from 'react-hot-toast';
import { BiLogOutCircle } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import BBButton from '@/base_blocks/src/bbbutton';
import { useStateManagerContext } from '@/components/react_wrappers/state_manager/context';
import { stateUser } from '@/states/auth';
import { isLoggedIn } from '@/utils/auth';
import Routes from 'routes';

/**
 * PROPS
 * @param {boolean?} showText - Whether or not to show 'logout' text
 * @param {string?} redirect - Where to redirect to after logout, defaults to window.location then home page
 */
interface IPropsButtonLogout {
  showText?: boolean;
  redirect?: string;
}

/**
 * BUTTON LOGOUT
 */
export default function ButtonLogout(Props: IPropsButtonLogout): React.ReactElement | null {
  const [user] = useRecoilState(stateUser);
  const { showText = false, redirect } = Props;
  const stateManagerContext = useStateManagerContext();
  const router = useRouter();

  const redirectRoute = () => {
    if (!!redirect && !!redirect.length) {
      return redirect;
    }
    return Routes.routeLogin();
  };

  const logout = () => {
    stateManagerContext.clearStateFunctions.clearAllStates(); // clear all states - token, user, etc.
    toast.success('Successfully logged out.');
    const route = redirectRoute();
    void router.push(route);
  };

  /**
   * RENDER
   */

  return isLoggedIn(user) ? <BBButton text={showText ? 'Logout' : undefined} icon={{ icon: <BiLogOutCircle /> }} onClick={logout} /> : null;
}
