import React from 'react';
import { useRecoilState } from 'recoil';
import ButtonDashboard from '@/components/buttons/button_dashboard';
import ButtonsAuth from '@/components/buttons/buttons_auth';
import ContainerFloating from '@/components/containers/floating';
import { stateUser } from '@/states/auth';
import { isLoggedIn } from '@/utils/auth';

/**
 * FLOATING BUTTON AUTH
 */
export default function FloatingButtonAuth(): React.ReactElement {
  const [user] = useRecoilState(stateUser);

  /**
   * RENDER
   */
  if (!user || !isLoggedIn(user)) {
    return (
      <ContainerFloating alignment="bottom_right">
        <ButtonsAuth showText elevation />
      </ContainerFloating>
    );
  } else {
    return (
      <ContainerFloating alignment="bottom_right">
        <ButtonDashboard />
      </ContainerFloating>
    );
  }
}
