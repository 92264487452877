import { useTheme } from 'next-themes';
import React from 'react';
import { BsFillMoonFill, BsFillSunFill } from 'react-icons/bs';
import BBButton from '@/base_blocks/src/bbbutton';
import styles from '@/components/buttons/button_theme_switcher/styles.module.scss';

/**
 * BUTTON THEME SWITCHER
 */
export default function ButtonThemeSwitcher(): React.ReactElement {
  const { theme, setTheme } = useTheme();
  /**
   * RENDER
   */

  return (
    <div className={styles.containerThemeSwitcher}>
      {theme == 'light' ? (
        <BBButton onClick={() => setTheme('dark')} variant="primary" icon={{ icon: <BsFillMoonFill /> }} />
      ) : (
        <BBButton onClick={() => setTheme('light')} variant="secondary" icon={{ icon: <BsFillSunFill /> }} />
      )}
    </div>
  );
}
