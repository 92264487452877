import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';
import BBCard from '@/base_blocks/src/bbcard';
import BBText from '@/base_blocks/src/bbtext';
import type { TBBTextSize } from '@/base_blocks/src/types';
import styles from '@/components/headers/page_header_main/styles.module.scss';
import Logo from '@/images/logo-default.png';

/**
 * PROPS
 *
 * @param {string} textHeader - Text for header
 * @param {string=} textSubtitle - Text for subtitle
 * @param {TBBTextSize=} sizeSubtitle - Size for subtitle
 * @param {boolean=} showLogo - Show logo
 */
interface IPropsPageHeaderMain {
  textHeader: string;
  textSubtitle?: string;
  sizeSubtitle?: TBBTextSize;
  showLogo?: boolean;
}

/**
 * PAGE HEADER MAIN
 */
export default function PageHeaderMain(Props: IPropsPageHeaderMain): React.ReactElement {
  const { textHeader, textSubtitle, sizeSubtitle = 'xlarge', showLogo = false } = Props;

  /**
   * RENDER
   */
  return (
    <BBCard elevation="high" className={classnames(styles.cardHeader)} colorBorder="secondary">
      <BBCard.Header className={styles.containerHeader}>
        <BBText size="xxxlarge">{textHeader}</BBText>
        {showLogo && (
          // eslint-disable-next-line
          <Image src={Logo.src} alt="" width="70" height="70" />
        )}
      </BBCard.Header>
      {!!textSubtitle && (
        <BBCard.Body>
          <div className={styles.containerSubtitle}>
            <BBText size={sizeSubtitle}>{textSubtitle}</BBText>
          </div>
        </BBCard.Body>
      )}
    </BBCard>
  );
}
