import classNames from 'classnames';
import React from 'react';
import type { TBBButtonSize } from '@/base_blocks/src/types';
import { ButtonLogin } from '@/components/buttons/button_login';
import { ButtonSignup } from '@/components/buttons/button_signup';
import styles from '@/components/buttons/buttons_auth/styles.module.scss';

/**
 * PROPS
 *
 * @param {boolean=} showText - Show text on button
 * @param {boolean=} elevation - Whether to show elevation
 * @param {TBBButtonSize=} size - Size of button
 * @param {string=} className - Class names to add to button
 * @param {boolean=} showLogin - Show login button
 * @param {boolean=} showSignup - Show signup button
 */
interface IPropsButtonsAuth {
  showText?: boolean;
  elevation?: boolean;
  size?: TBBButtonSize;
  className?: string;
  showLogin?: boolean;
  showSignup?: boolean;
}

/**
 * BUTTONS AUTH
 */
export default function ButtonsAuth(Props: IPropsButtonsAuth): React.ReactElement {
  const { showText = false, elevation = false, size = 'sm', className, showLogin = true, showSignup = true } = Props;

  /**
   * RENDER
   */
  return (
    <div className={classNames(className, styles.container)}>
      {showLogin && <ButtonLogin size={size} showText={showText} elevation={elevation} />}
      {showSignup && <ButtonSignup size={size} showText={showText} elevation={elevation} />}
    </div>
  );
}
