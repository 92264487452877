import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FaBook, FaCog } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import BBButton from '@/base_blocks/src/bbbutton';
import BBModal from '@/base_blocks/src/bbmodal';
import BBText from '@/base_blocks/src/bbtext';
import styles from '@/components/buttons/floating_button_management/styles.module.scss';
import FloatingContainer from '@/components/containers/floating';
import { stateUser } from '@/states/auth';
import { stateProjectSettings } from '@/states/core';
import { isLoggedIn } from '@/utils/auth';
import Routes from 'routes';

/**
 * FLOATING BUTTON MANAGEMENT
 */
export default function FloatingButtonManagement(): React.ReactElement | null {
  // recoil
  const [user] = useRecoilState(stateUser);
  const [projectSettings] = useRecoilState(stateProjectSettings);

  // local
  const [showModal, setShowModal] = useState<boolean>(false);

  const router = useRouter();

  if (!user || !isLoggedIn(user) || !user.is_staff) {
    return null;
  }

  /**
   * RENDER
   */
  return (
    <FloatingContainer alignment="bottom_left" className={styles.containerVerticalNavAdjustments}>
      <BBButton
        text="Management"
        variant="secondary"
        icon={{ icon: <FaCog /> }}
        onClick={() => {
          setShowModal(true);
        }}
      />
      {showModal && (
        <BBModal title="Management" onDismiss={() => setShowModal(false)}>
          <div className={styles.containerBody}>
            <BBText size="large">Visit management portal</BBText>
            <BBButton
              text="Management Portal"
              variant="secondary"
              icon={{ icon: <FaBook /> }}
              onClick={() => router.push(Routes.routeAdmin())}
            />
          </div>
          <div className={styles.containerProjectSettings}>
            <BBText size="large">Project Settings</BBText>
            <BBText>Name {projectSettings.name}</BBText>
            <BBText>Description {projectSettings.description}</BBText>
            <BBText color="primary">Version {projectSettings.version}</BBText>
            <BBText color="secondary">Environment {projectSettings.environment}</BBText>
          </div>
        </BBModal>
      )}
    </FloatingContainer>
  );
}
